define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/fd4481d47324cbe25a6e938a40185203de24746b/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/mapValues"], function (declare, mapValues) {
  var ValidationError = declare([Error], {
    constructor: function (message, code, errors) {
      this.message = message;
      this.code = code;
      this.errors = errors;
    }
  });
  ValidationError.createFromResponseData = function (responseData) {
    var message = responseData.message;
    var code = responseData.code;
    var errors = mapValues(responseData.errors, function (error) {
      var validationError = ValidationError.createFromResponseData(error);
      return validationError;
    });
    var error = new ValidationError(message, code, errors);
    return error;
  };
  return ValidationError;
});