define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/fd4481d47324cbe25a6e938a40185203de24746b/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo) {
  // module:
  //		dijit/main

  /*=====
  return {
  	// summary:
  	//		The dijit package main module.
  	//		Deprecated.   Users should access individual modules (ex: dijit/registry) directly.
  };
  =====*/

  return dojo.dijit;
});